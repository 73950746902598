















import { mapGetters } from 'vuex';
import { getUserPromoCampaign } from '@/api/PromoCampaign';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { PromoCampaignByUser } from '@/api/schema';

export default {
  name: 'UserManagementPromoCampaignsTab',
  data: (): unknown => ({
    promo: [],
    loading: false,
    headers: [
      {
        text: 'ID',
        sortable: false,
        value: 'id',
        width: '30%'
      },
      {
        text: 'Campaign Name',
        sortable: false,
        value: 'name',
        width: '40%'
      },
      {
        text: 'Status',
        sortable: false,
        value: 'status',
        width: '20%'
      }
    ]
  }),
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getUserPromo();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId'])
  },
  methods: {
    getUserPromo(): Promise<unknown> {
      if (!this.userId) return;

      this.loading = true;

      return getUserPromoCampaign(this.userId)
        .then((data: PromoCampaignByUser[]): void => {
          this.promo = data;
        })
        .catch(errorToastMessage)
        .finally((): void => {
          this.loading = false;
        });
    }
  }
};
